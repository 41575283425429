import * as React from "react"
import { Box, Flex, Link } from "@chakra-ui/react"
import HomeLayout from "../components/HomeLayout"
import { Link as GatsbyLink } from "gatsby"

const LandingPage = (): JSX.Element => (
  <HomeLayout pageTitle={"facetious fidelity"}>
    <Box
      className={"landing-page-component"}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      mt={5}
      id={"landing-page-component"}
    >
      <Flex
        as={"nav"}
        role={"navigation"}
        id={"landing-page-nav"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={6}
        fontSize={"lg"}
      >
        <GatsbyLink to={"/about"}>about</GatsbyLink>{" "}
        <GatsbyLink to={"/projects"}>projects</GatsbyLink>{" "}
        <GatsbyLink to={"/word"}>word</GatsbyLink>{" "}
        <GatsbyLink to={"/iocoop"}>io coöperative</GatsbyLink>{" "}
        <Link href={"https://cv.wryfi.net/"}>vitae</Link>{" "}
      </Flex>
    </Box>
  </HomeLayout>
)

export default LandingPage
